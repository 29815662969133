@import "../../scss/abstract/variables";

.navbar {
  z-index: 1;
  color: $dark-gray-text;
  height: $navbar-height;
  background: getColor(blue-gray-0);
  box-shadow: $shadow-navbar;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100vw;
    z-index: 3;

    .factory-name {
      font-size: 16px;
      font-weight: 600;
      color: hsl(201deg, 10%, 33%);
      margin: auto;
    }

    @media (max-width: $breakpoint-sm) {
      .factory-name {
        display: none;
      }
    }

    &__left {
      display: flex; // Flex inside left container
      align-items: center;
      justify-content: flex-start;

      .hamburger-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        svg {
          fill: getColor(gray-6);
          width: 24px;
          height: 24px;

          &:hover,
          &:active {
            fill: $primary;
            color: $primary;
          }
        }
      }

      h3 {
        margin-left: 5px;
      }

      .logo-container {
        display: flex;

        .name {
          font-size: 20px;
          font-weight: 600;
          color: hsl(201deg, 10%, 33%);
          margin: auto;
        }

        .logo {
          width: 40px;
          height: 40px;
          margin: 0 10px 0 10px;
        }
      }

      @media (max-width: $breakpoint-md) {
        .logo-container {
          .name {
            display: none;
          }
        }
      }
    }

    @media (max-width: $breakpoint-sm) {
      &__left {
        max-width: 50%;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      #languages-ddl {
        margin-right: 10px;
      }

      .dropdown-user-profile {
        .dropdown-menu {
          width: 210px;

          .dropdown-item {
            height: 40px;
            display: flex;
            align-items: center;

            &:hover {
              background-color: getColor(gray-0);
            }

            svg {
              fill: getColor(gray-8);
              height: 18px;
              width: 18px;
            }

            &:active {
              svg {
                fill: white;
              }
            }
          }
        }
      }
    }

    svg {
      fill: getColor(gray-8);
      height: 20px;
      width: 20px;
    }

    .round-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;

      &:hover {
        background-color: getColor(gray-1);
      }
    }
  }
}
.profile-avatar {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.profile-preview-avatar {
  margin-top: 4px;
  margin-right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
  background-color: getColor(gray-5);
}

.custom-language-dropdown {
  outline: none;

  .custom-select__single-value {
    display: flex;
    align-items: center;
  }

  .country-option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .country-logo {
    width: 28px;
    margin-right: 8px;
  }

  .selected-logo {
    width: 28px;
    margin-right: 8px;
    align-items: center;
  }
}

.custom-factory-dropdown {
  width: 180px;

  .factory-option {
    display: flex;
    gap: 16px;
    padding: 16px;

    svg {
      fill: getColor(gray-7) !important;
      background-color: getColor(gray-1);
      border-radius: 50%;
      width: 28px !important;
      height: 28px !important;
      padding: 6px;
    }

    .detail-box {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .custom-menu {
    width: 320px !important;
  }

  .menu-header {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid getColor(gray-1);

    span:last-of-type {
      color: getColor(primary-blue-5);
      cursor: pointer;
    }
  }

  .custom-option {
    border-bottom: 1px solid getColor(gray-1);

    span:first-child {
      font-size: 16px;
      color: getColor(gray-7);
    }

    span:last-of-type {
      font-size: 12px;
      color: getColor(gray-5);
    }
    padding: 0px;

    &.selected {
      background-color: transparent !important;

      .select-dot {
        background-color: getColor(primary-blue-5);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-block: auto;
      }
    }
    &.focused {
      background-color: getColor(gray-0) !important;
    }
  }

  .custom-menu-list {
    padding: 0px !important;
    border-radius: 8px !important;
  }

  .custom-single {
    width: 100px;
  }
}
