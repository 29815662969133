@use "../../scss/animation.scss";
@import "../../scss/abstract/variables.scss";

$nav-item-height: 3.45rem;

.sidebar {
  z-index: 0;
  color: $sidebar-content;
  fill: $sidebar-content;
  background: getColor(blue-gray-0);
  border-right: 1px solid getColor(gray-2);
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  max-height: calc(100vh - $navbar-height);
  overflow-y: auto;
  overflow-x: hidden;
  transition: ease 0.5s;

  // Sidebar collapsed style & animation
  &.collapsed {
    min-width: 0px;
    max-width: 0px;
    overflow: hidden;
    transition: ease 0.4s;
    min-width: 0px;

    // Hide selected sidebar
    .side__group,
    .nav-item {
      &.selected {
        &::after {
          width: 0px;
        }
      }
    }
  }

  // Put center and bottom apart
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  .side__group {
    &_item {
      margin-left: 1.5rem;
      color: $primary;
    }

    &_arrow {
      cursor: pointer;
      margin-right: 0.8rem;
      color: $sidebar-content;
    }
  }

  .center {
    .nav {
      margin-top: 4px;
    }
  }

  .bottom {
    font-size: 0.75rem;
    min-width: $sidebar-width;
    height: $nav-item-height;
    display: flex;
    // justify-content: center;
    align-items: center;
    padding-left: 24px;
    margin-bottom: 21px;

    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 22px;
      font-size: 1rem;
    }

    &::after {
      content: "";
      background-color: getColor(primary-blue-1);
      border-radius: 8px;
      width: 0;
      height: $nav-item-height;
      position: absolute;
      transition: ease 0.4s;
      z-index: -1;
    }

    &:hover {
      &:not(.selected)::after {
        background-color: getColor(gray-1);
        width: calc($sidebar-width - 16px);
        left: 8px;
        right: 8px;
      }
    }
  }

  .side__group,
  .nav-item {
    white-space: nowrap;
    height: $nav-item-height;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;

    .sidebar-content {
      color: $sidebar-content;
      fill: $sidebar-content;
      padding: 12px;
      margin-inline: 10px;
    }

    .nav-link {
      width: 100%;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }

    span {
      margin-left: 20px;
      margin-block: auto;
      font-size: 1rem;
    }

    &:hover {
      &:not(.selected)::after {
        background-color: getColor(gray-1);
        width: calc($sidebar-width - 16px);
        left: 8px;
        right: 8px;
      }

      .sidebar-content {
        transition: ease 0.5s;
      }

      svg {
        animation: swing ease 0.5s 1 alternate;
      }
    }

    &::after {
      content: "";
      background-color: getColor(primary-blue-1);
      border-radius: 10px;
      width: 0;
      height: $nav-item-height;
      position: absolute;
      transition: ease 0.4s;
      z-index: -1;
    }

    &.selected {
      &::after {
        width: calc($sidebar-width - 16px);
        left: 8px;
        right: 8px;
      }

      .sidebar-content,
      .side__group_item {
        color: getColor(primary-blue-5);
        fill: getColor(primary-blue-5);
        font-weight: 500;
      }
    }
  }
}

@media (max-width: ($breakpoint-md)) {
  .sidebar {
    position: absolute;
    height: calc(100vh - $navbar-height);
    z-index: 1000;
  }
}
