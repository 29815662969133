// ====================================
// Kanit
// ====================================
@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-Bold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit';
    src: url(~/public/fonts/Kanit-ExtraBold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

// ====================================
// Prompt
// ====================================
@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-Bold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Prompt';
    src: url(~/public/fonts/Prompt-ExtraBold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}