@import "../../scss/abstract/variables";
@import "../../scss/fontStyle.scss";

.delete-user-card {
  .card {
    padding: 24px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.13);
    width: 500px;

    .card-title {
      @extend .title-text;
      color: getColor(gray-7);
    }

    .card-content {
      .title {
        @extend .regular-text;
        color: var(--gray-7, #4c575d);
        margin-top: 36px;
        max-width: 95%;
      }

      .form-wrapper {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .form-group {
          width: 100%;
        }

        .button-section {
          display: flex;
          gap: 8px;
          margin-left: auto;

          &--delete {
            background: var(--pink-4, #ff5792);
          }
        }
      }
    }
  }
}

.success-delete-card {
  @extend .delete-user-card;

  min-width: 390px;
  max-width: 390px;

  .card-body {
    .success-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 42px;

      .check-circle-fill {
        fill: getColor(light-green-7);
      }

      &--title {
        @extend .title-text;
        color: getColor(gray-7);
        font-size: 24px;
      }
    }
  }
}
