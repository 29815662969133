// Contains all Animations throughout the project.

@keyframes fade-in-right {
    0% {
        opacity: 0;
        transform: translateX(10%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-left {
    0% {
        opacity: 0;
        transform: translateX(-20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
      transform: rotate(0deg);
    }
  
    10% {
      transform: rotate(10deg);
    }
  
    40% {
      transform: rotate(-10deg);
    }
  
    60% {
      transform: rotate(5deg);
    }
  
    80% {
      transform: rotate(-5deg);
    }
}

@keyframes button-pressed {
  0%{
      transform: scale(1);
  }
  100%{
      transform: scale(.95);
  }
}