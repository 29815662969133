@import '../../scss/abstract/variables';

@keyframes gradients {
    0% {
        background-position: 0 0;
    }

    20% {
        background-position: 40% 0;
    }

    40% {
        background-position: 100% 0%;
    }

    60% {
        background-position: 80% 0;
    }

    80% {
        background-position: 60% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.background-bubbles {
    z-index: -2;
    background-image: linear-gradient(-160deg, rgba(92, 38, 245, 0.35) 0%, rgba(0, 159, 245, 0.35) 32.81%, rgba(131, 156, 243, 0.35) 67.57%, rgba(239, 160, 246, 0.35) 100%);
    background-size: 250%;

    /* Animation */
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: gradients;
    animation-timing-function: ease;
}

// ------------------ Bubble Animation -------------------------

.bubbles {
    z-index: -1;
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;

    .bubble {
        position: absolute;
        bottom: -100px;
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.3;
        animation: rise 10s infinite ease-in;

        &:nth-child(1) {
            width: 40px;
            height: 40px;
            left: 25%;
            animation-duration: 30s;
        }

        &:nth-child(2) {
            width: 30px;
            height: 30px;
            left: 20%;
            animation-duration: 20s;
            animation-delay: 1s;
        }

        &:nth-child(3) {
            width: 50px;
            height: 50px;
            left: 15%;
            animation-duration: 35s;
            animation-delay: 2s;
        }

        &:nth-child(4) {
            width: 90px;
            height: 90px;
            left: 10%;
            animation-duration: 70s;
            animation-delay: 0s;
        }

        &:nth-child(5) {
            width: 75px;
            height: 75px;
            left: 35%;
            animation-duration: 50s;
            animation-delay: 1s;
        }

        &:nth-child(6) {
            width: 30px;
            height: 30px;
            left: 60%;
            animation-duration: 30s;
            animation-delay: 3s;
        }

        &:nth-child(7) {
            width: 50px;
            height: 50px;
            left: 75%;
            animation-duration: 40s;
            animation-delay: 4s;
        }

        &:nth-child(8) {
            width: 120px;
            height: 120px;
            left: 80%;
            animation-duration: 90s;
            animation-delay: 1s;
        }

        &:nth-child(9) {
            width: 15px;
            height: 15px;
            left: 70%;
            animation-duration: 18s;
            animation-delay: 3s;
        }
    }
}

@keyframes rise {
    0% {
        bottom: -100px;
        transform: translateX(0);
    }

    50% {
        transform: translate(100px);
    }

    100% {
        bottom: 1080px;
        transform: translateX(-200px);
    }
}