@import "../scss/abstract/variables";

// Custom Bootstrap Maintheme before the lib is loaded.
$primary: #009ff5; //primary-blue-5
$secondary: #606e76; //gray-6
$warning: #ffc457; //yellow-4
$danger: #ff2974; //pink-5
$success: #1fd65f; //light-green-7
$info: #6434f4; //violet-5

// Override bootstrap default font color
$body-color: #4c575d; // gray-7

// Theme Button Shadow Effects
$button-shadow-primary: 0px 2px 15px 0px rgba($primary, 0.75);
$button-shadow-secondary: 0px 2px 15px 0px rgba($secondary, 0.75);
$button-shadow-warning: 0px 2px 15px 0px rgba($warning, 0.75);
$button-shadow-danger: 0px 2px 15px 0px rgba($danger, 0.75);
$button-shadow-success: 0px 2px 15px 0px rgba($success, 0.75);
$button-shadow-info: 0px 2px 15px 0px rgba($info, 0.75);

// Theme Input box
$input-border-color: getColor(gray-2);
$input-border-radius: 8px;
$form-label-color: getColor(gray-7);

//breadcrumb
$breadcrumb-divider: quote(">");
$breadcrumb-divider-color: getColor(gray-6);
$breadcrumb-active-color: getColor(gray-7);
$breadcrumb-font-size: 20px;
$breadcrumb-margin-bottom: 32px;
// Import Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap";

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
      Write your custom CSS styles which will override bootstrap default style only in this file!

                                                THANK YOU
    ==============================================================================================
*/

body {
  .modal-header {
    border-bottom: none;
    padding: 1.5rem;
    color: getColor(gray-7);

    .modal-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }

  .modal-body {
    padding-left: 54px;
    padding-right: 54px;
  }

  .modal-footer {
    border-top: none;
    padding-left: 54px;
    padding-right: 54px;
    padding-bottom: 34px;
  }

  .modal-content {
    top: 10vh;
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);
    border-radius: 12px;
  }
}

/*
========== Buttons ==========
*/
body .btn {
  min-width: 100px;
  height: 30px;
}

// Overrides BS theme color
.btn {
  &-md {
    height: 2.5rem;

    svg {
      font-size: 20px;
    }
  }

  // On Primary Button Pressed
  &-primary,
  &-outline-primary {
    &:active {
      animation: button-pressed 0.05s 1 forwards;
    }
  }

  &-primary,
  &-success,
  &-danger {
    color: white;

    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }

  &-outline-primary,
  &-outline-success,
  &-outline-danger {
    &:hover {
      color: white;
    }
  }

  // Modify Theme Button Shadow Effects
  &:hover,
  &:active,
  &:focus {
    &.btn-primary {
      box-shadow: $button-shadow-primary;
      transition: ease 0.5s;
    }

    &.btn-secondary {
      box-shadow: $button-shadow-secondary;
      transition: ease 0.5s;
    }

    &.btn-warning {
      box-shadow: $button-shadow-warning;
      transition: ease 0.5s;
    }

    &.btn-danger {
      box-shadow: $button-shadow-danger;
      transition: ease 0.5s;
    }

    &.btn-success {
      box-shadow: $button-shadow-success;
      transition: ease 0.5s;
    }

    &.btn-info {
      box-shadow: $button-shadow-info;
      transition: ease 0.5s;
    }
  }
}

/*
========== breadcrumb ==========
*/

.breadcrumb {
  padding-top: 32px;

  .breadcrumb-item {
    font-size: 20px;
    font-weight: 500;
    a {
      color: getColor(gray-4);
      text-decoration: none;

      &:hover {
        color: getColor(primary-blue-5);
      }
    }
    &.active {
      color: getColor(gray-7);
    }
  }
}

.form-select {
  cursor: pointer;
}

.container-wrapper {
  padding-inline: 32px;
}
