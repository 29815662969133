@import "../../scss/abstract/variables";

.dropdown-notification {
  .notification-box {
    display: flex;
    gap: 12px;

    .notification-icon {
      width: 24px !important;
      height: 24px !important;
      flex-shrink: 0;

      &.blue {
        fill: getColor(blue-5);
      }

      &.yellow {
        fill: getColor(yellow-6);
      }

      &.pink {
        fill: getColor(pink-7);
      }
    }
  }
  .round-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 40px;

    &:hover {
      background-color: getColor(blue-4);
    }
  }

  .notification-content {
    background-color: red;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 8px;
    right: 15px;
    border-radius: 50%;
  }

  .menu-dropdown {
    max-height: 500px;
    width: 380px;
    overflow-x: hidden;

    .text-noti-new {
      border-bottom: 1px solid getColor(light-gray-3);
      padding: 1rem 1rem;
      background-color: #ffffff;

      .text-date {
        font-size: 12px;
        color: getColor(gray-5);
        padding-top: 5px;
      }

      .text-title {
        padding: 2px 0px;
        font-weight: 500;

        &.blue {
          color: getColor(blue-5);
        }

        &.yellow {
          color: getColor(yellow-6);
        }

        &.pink {
          color: getColor(pink-7);
        }
      }

      .text-message {
        color: getColor(gray-7);
        width: 100%;
        white-space: initial;
      }
    }

    .text-noti-read {
      border-bottom: 1px solid getColor(light-gray-4);
      padding: 1rem 1rem;
      background-color: getColor(light-gray-2);

      .text-date {
        font-size: 10px;
        color: getColor(gray-5);
        padding-top: 5px;
      }

      .text-title {
        padding: 2px 0px;
        font-weight: 500;
        color: getColor(gray-7);
      }

      .text-message {
        color: getColor(gray-7);
        width: 100%;
        white-space: initial;
      }
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: getColor(blue-0);
    }

    .dropdown-item.text-noti-read:hover,
    .dropdown-item.text-noti-read:focus {
      background-color: getColor(light-gray-3);
    }

    inset: 0px 0px auto auto;
    transform: translate3d(0px, 42.4px, 0px);
  }

  .dropdown {
    .dropdown-menu {
      inset: 0px 0px auto auto;
      transform: translate3d(0px, 42.4px, 0px);
    }
  }

  .title-menu {
    font-size: 16px;
    color: getColor(gray-7);
    justify-content: center;
    display: flex;
  }

  .button-view {
    color: getColor(blue-4);
    border: 0rem;
    padding-top: 5px;
    margin-left: 10px;
    background-color: white;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-sm) {
  .dropdown-notification {
    .menu-dropdown {
      width: 310px;
    }
  }
}
