@import '../scss/abstract/variables';

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
                                Only use app.scss to define global styles

                                                THANK YOU
    ==============================================================================================
*/

// Button size for buttons icon only
.btn.icon-only {
    display: inline-block;
    min-width: 32px;
    height: 32px;
}

// General style for button with icon and text
.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        height: 20px;
        width: 20px;
    }

    .icon~span {
        margin-left: .5rem;
    }
}

//for form invalid message
.invalid-feedback {
    text-align: left;
}

.offline-message-wrap {
    position: absolute;
    z-index: 1010;
    top: 72px;
    left: calc(50vw - (/* width */240px / 2)); 

    .offline-message {
        text-align: center;
        width: 240px;
        background-color: getColor(gray-7);
        color: white;
        padding: 6px 8px;
        border-radius: 4px;
    }
}

.language-bar{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
}

.report-header {
    display: flex;
    width: 100%;
    height: 75px;
    justify-content: space-between;

    &__group {
      width: 18rem;

      .form-select {
        font-weight: 400;
        color: rgb(76, 87, 93);
      }
    }

    &__date {
      .date-period-filter {
        height: 38px;
      }
    }
  }

  .chart-spinner {
    width: 4rem;
    height: 4rem;
    font-size: 1rem;
  }

  .button-content {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }

  @media (max-width: $breakpoint-sm) {
    .report-header {
      flex-direction: column;
      height: unset;

      &__group,
      &__date {
        margin-bottom: 10px;
      }

      &__date {
        height: unset;
      }
    }
    .button-content {
      justify-content: flex-start;
    }
  }

  .card-table-header {
    .btn, .form-select {
      height: 38px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    .btn-md {
      width: 140px;
    }
  }
