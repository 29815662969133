.regular-text {
    font-style: normal;
    font-weight: 400;
    line-height: 150%
}

.title-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}